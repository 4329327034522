import React from "react" 
import { Link, graphql } from "gatsby"

import Layout from "components/layout.js"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout 
    // location={this.props.location} 
    // title={siteTitle}
    > 
      
      <div className="jumbotron page-header">
        <div className="container">
          <h1 className="display-32">News</h1>
        </div>
      </div>
      <div className="container"> 
        <h1>{frontmatter.title}</h1>
        <div className="blog-post-container news">
          <div className="blog-post">
            {/* <h1>{frontmatter.title}</h1> */}
            <h2>{frontmatter.date}</h2>
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </div>
      </div>
      </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } , published: { eq: true } }) {
      html
      id
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        slug
        published
      }
    }
  }
`  